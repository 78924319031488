.butt {
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
}

.Student-table {
  margin: auto;
  margin-top: 20;

  width: 500px;
}

.contt {
  border-radius: 25.82px;
  border: 2.582px solid rgba(0, 0, 0, 0.09);
  background-color: white;
  width: 700px;

  /* height: 550px; */
  height: 70vh;
  display: flex;
  padding: 20px;
  flex-direction: column;
  border: 3px solid rgba(0, 0, 0, 0.1);
}


.notify{
    height: 10;
    width: 30;
}


.header-color {
    /* color: #305D90; */
    color: #C0C0C0 ;	
    font-size: 20px;
  }

  .data-color{
    color: #2C6AB1;
    font-size:20px;
  }


.MuiDataGrid-cellContent {
  color: #acbfd4;
  font-size: 20px;
}

.studentSearch .MuiDataGrid-columnHeaders {
  display: none;
}

.studentSearch .MuiDataGrid-footerContainer {
  display: none;
}

.single-invite-button {
  height: 30px;
  margin-top: 10px;
  font-size: 20px;
  cursor: pointer;
  background-color: #0000000D;
  color: #2C6AB1;
  font-weight: 700;
  padding-left: 10px;
  padding-right: 10px;
  width: fit-content;

  &:disabled {
    cursor: auto;
    opacity: 0.7;
  }
}

.single-notify-button {
  height: 30px;
  width: 90px;
  margin-top: 10px;
  font-size: 20px;
  cursor: pointer;
  background-color: #0000000D;
  color: #2C6AB1;
  font-weight: 700;

  &:disabled {
    cursor: auto;
    opacity: 0.7;
  }
}