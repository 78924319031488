.log-all-out {
    cursor: pointer;
}

.logo-icon-invalid {
   position: relative !important;
   left: auto !important;
   margin: 40px;
   width: auto;
   height: 120px;
}